import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BackToTop from "../../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Michael = () => {
  return (
    <Layout>
      <Seo title="Doctor Michael Bauer, DVM" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero no-videoHero">
              <h1>
                Meet <br /> Dr. Michael <br /> Bauer
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Container className="acl-container mb-5 mt-5 mt-md-0">
        <div className="doctor-body">
          <Row className="border-none">
            <Col md={4} className="img-resp">
              <LazyLoadImage
                effect="blur"
                src="/assets/img/bauer_2015.jpg"
                alt="Doctor"
                className="img-fluid mb-4"
              />
            </Col>
            <Col md={8}>
              <p>
                Dr. Michael Bauer is the founder of Colorado Canine
                Orthopedics - he received his DVM degree from Auburn University
                and completed his surgical residency at the University of
                Tennessee. Driven by a commitment to academic excellence and
                innovation in veterinary surgery, Dr. Bauer dedicated a decade
                to teaching at esteemed institutions including the University of
                Saskatchewan, Purdue University, and Colorado State University. His
                academic tenure not only enriched the veterinary community but also
                paved the way for his future endeavors.
              </p>
              <p>
                In 1996, Dr. Bauer's path took a pivotal turn when he moved to
                Colorado Springs and established a small animal surgical practice.
                Dr. Bauer's lifelong dedication to animal welfare, including work
                with local humane societies and animal rescue groups, reflects his
                deep-seated belief in the importance of the human-animal bond. His
                dedication to animal welfare, combined with his dedication to
                orthopedic surgical excellence culminated in one of the country's
                most well respected (and largest) small animal orthopedic practices.
              </p>
              <p>
                Following retirement, Dr. Bauer enjoys spending time with pets and
                outdoor activities including cycling, skiing and fly-fishing. His
                legacy at Colorado Canine Orthopedics endures, embodying his ethos
                of treating every pet and pet owner with the same care and respect
                he desires for his own animal family.
              </p>
            </Col>
          </Row>
        </div>
        <div className="doctor-video">
          <Row className="border-none">
            <Col md={12}>
              <div className="embed-container">
                <iframe
                  src="https://player.vimeo.com/video/445627889"
                  frameborder="0"
                  webkitAllowFullScreen
                  mozallowfullscreen
                  allowFullScreen
                  title="doctor"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <BackToTop />
      {/* </div> */}
    </Layout>
  )
}

export default Michael
